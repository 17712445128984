<ngb-alert class="blink_me mobile" type="info" style="display: none;" *ngIf="showToastWithTourOnMessage" (closed)="showToastWithTourOnMessage = false">
  {{tourMessage}}
</ngb-alert>
<!-- Topbar Start -->
<div class="navbar-custom">
  <div [class]="containerClass">
    <ul class="list-unstyled topnav-menu float-end mb-0">

      <li class="d-none d-lg-block" ngbDropdown placement="bottom-start">
        <!--                <form class="app-search">-->
        <!--                    <div class="app-search-box">-->
        <!--                        <div class="input-group">-->
        <!--                            <input type="text" class="form-control" placeholder="Buscar..." id="search-dropdown"-->
        <!--                                ngbDropdownToggle>-->
        <!--                            <button class="btn input-group-text" type="submit">-->
        <!--                                <i class="fe-search"></i>-->
        <!--                            </button>-->
        <!--                        </div>-->
        <!--                        <div class="dropdown-lg" id="search-dropdown" ngbDropdownMenu>-->
        <!--                            &lt;!&ndash; item&ndash;&gt;-->
        <!--                            <div class="dropdown-header noti-title" ngbDropdownItem>-->
        <!--                                <h5 class="text-overflow mb-2">22 resultados encontrados</h5>-->
        <!--                            </div>-->

        <!--                            &lt;!&ndash; item&ndash;&gt;-->
        <!--                            <a href="javascript:void(0);" class="dropdown-item notify-item"-->
        <!--                                *ngFor="let result of searchResults">-->
        <!--                                <i class="{{result.icon}} me-1"></i>-->
        <!--                                <span>{{result.text}}</span>-->
        <!--                            </a>-->

        <!--                            &lt;!&ndash; item&ndash;&gt;-->
        <!--                            <div class="dropdown-header noti-title">-->
        <!--                                <h6 class="text-overflow mb-2 text-uppercase">Users</h6>-->
        <!--                            </div>-->

        <!--                            <div class="notification-list">-->
        <!--                                &lt;!&ndash; item&ndash;&gt;-->
        <!--                                <a href="javascript:void(0);" class="notify-item" ngbDropdownItem-->
        <!--                                    *ngFor="let user of searchUsers">-->
        <!--                                    <div class="d-flex align-items-start">-->
        <!--                                        <img class="d-flex me-2 rounded-circle" [src]="user.profile" [alt]="user.name"-->
        <!--                                            height="32">-->
        <!--                                        <div class="w-100">-->
        <!--                                            <h5 class="m-0 fs-14">{{user.name}}</h5>-->
        <!--                                            <span class="font-12 mb-0">{{user.position}}</span>-->
        <!--                                        </div>-->
        <!--                                    </div>-->
        <!--                                </a>-->
        <!--                            </div>-->

        <!--                        </div>-->
        <!--                    </div>-->
        <!--                </form>-->
      </li>

      <!--            <li class="dropdown d-inline-block d-lg-none" ngbDropdown autoClose="outside" placement="bottom-end">-->
      <!--                <a class="nav-link dropdown-toggle arrow-none waves-effect waves-light" href="javascript:void(0);"-->
      <!--                    role="button" ngbDropdownToggle>-->
      <!--                    <i class="fe-search noti-icon"></i>-->
      <!--                </a>-->
      <!--                <div class="dropdown-lg dropdown-menu-end p-0" ngbDropdownMenu>-->
      <!--                    <form class="p-3" ngbDropdownItem>-->
      <!--                        <input type="text" class="form-control" placeholder="Search ..."-->
      <!--                            aria-label="Recipient's username">-->
      <!--                    </form>-->
      <!--                </div>-->
      <!--            </li>-->

      <!--            <li class="notification-list topbar-dropdown" ngbDropdown>-->
      <!--                <a class="nav-link dropdown-toggle waves-effect waves-light" href="javascript:void(0)" role="button"-->
      <!--                    ngbDropdownToggle>-->
      <!--                    <i class="fe-bell noti-icon"></i>-->
      <!--                    <span class="badge bg-danger rounded-circle noti-icon-badge">1</span>-->
      <!--                </a>-->
      <!--                <div class="dropdown-menu-end dropdown-lg" ngbDropdownMenu>-->

      <!--                    &lt;!&ndash; item&ndash;&gt;-->
      <!--                    <div class="noti-title" ngbDropdownItem>-->
      <!--                        <h5 class="m-0">-->
      <!--                            <span class="float-end">-->
      <!--                                <a [routerLink]="['.']" class="text-dark">-->
      <!--                                    <small>Borrar todo</small>-->
      <!--                                </a>-->
      <!--                            </span>Notificaciones-->
      <!--                        </h5>-->
      <!--                    </div>-->

      <!--                    <ngx-simplebar class="noti-scroll">-->

      <!--                        &lt;!&ndash; item &ndash;&gt;-->
      <!--                        <a href="javascript:void(0);" class="notify-item" *ngFor="let item of notificationList"-->
      <!--                            [class.active]="item.isActive" ngbDropdownItem>-->
      <!--                            <div class="notify-icon" *ngIf="item.avatar">-->
      <!--                                <img [src]="item.avatar" class="img-fluid rounded-circle" alt="">-->
      <!--                            </div>-->
      <!--                            <div class="notify-icon bg-{{item.bgColor}}" *ngIf="item.icon">-->
      <!--                                <i [class]="item.icon"></i>-->
      <!--                            </div>-->
      <!--                            <p class="notify-details" *ngIf="!item.avatar">{{item.text}}-->
      <!--                                <small class="text-muted">{{item.subText}}</small>-->
      <!--                            </p>-->
      <!--                            <ng-container *ngIf="item.avatar">-->
      <!--                                <p class="notify-details">{{item.text}}</p>-->
      <!--                                <p class="text-muted mb-0 user-msg">-->
      <!--                                    <small>{{item.subText}}</small>-->
      <!--                                </p>-->
      <!--                            </ng-container>-->
      <!--                        </a>-->

      <!--                    </ngx-simplebar>-->

      <!--                    &lt;!&ndash; All&ndash;&gt;-->
      <!--                    <a routerLink="/notifications" class="dropdown-item text-center text-primary notify-item notify-all">-->
      <!--                        Ver todo-->
      <!--                        <i class="fe-arrow-right"></i>-->
      <!--                    </a>-->

      <!--                </div>-->
      <!--            </li>-->
      <li class="dropdown notification-list">
        <div class="right-bar-toggle waves-effect waves-light mt-1" style="padding-left: 5px;">
          <ngb-alert class="blink_me desktop" type="info" *ngIf="showToastWithTourOnMessage" (closed)="showToastWithTourOnMessage = false">
            {{tourMessage}}
          </ngb-alert>
        </div>
      </li>
      <li class="dropdown notification-list">
        <div class="nav-link right-bar-toggle waves-effect waves-light" style="padding-right: 0;">
          <div class="form-switch">
            <label class="switch">
              <input type="checkbox" (click)="changeTourState()" [checked]="tourState">
              <span class="slider round"></span>
            </label>
          </div>
        </div>
      </li>
      <li class="dropdown notification-list">
        <div class="nav-link right-bar-toggle waves-effect waves-light" style="padding-left: 5px;">
          <i class="mdi mdi-help-circle-outline" style="font-size: large;"></i><span class="tour-info"> Tour <strong>{{state}}</strong></span>
        </div>
      </li>
      <li class="notification-list topbar-dropdown profile" ngbDropdown>
        <a class="nav-link dropdown-toggle nav-user me-0 waves-effect waves-light" href="javascript:void(0)"
           role="button" ngbDropdownToggle>
          <img [src]="loggedInUser?.avatar" alt="user-image" class="rounded-circle">
          <span class="pro-user-name ms-1">
                        {{loggedInUser?.name}} <i class="mdi mdi-chevron-down"></i>
                    </span>
        </a>
        <div ngbDropdownMenu class="dropdown-menu-end profile-dropdown ">

          <ng-container *ngFor="let option of profileOptions;let i=index">
            <div class="dropdown-divider" *ngIf="i===profileOptions.length-1"></div>
            <a [routerLink]="option.redirectTo" class="notify-item" ngbDropdownItem>
              <i [class]="option.icon"></i>
              <span style="cursor: pointer;">{{option.label}}</span>
            </a>
          </ng-container>

        </div>
      </li>

      <li class="dropdown notification-list">
        <a href="javascript:void(0);" class="nav-link right-bar-toggle waves-effect waves-light"
           (click)="toggleRightSidebar()">
          <i class="fe-eye noti-icon"></i>
        </a>
      </li>

    </ul>

    <!-- LOGO -->
    <div class="logo-box">
      <a routerLink="/" class="logo logo-light text-left">
                <span class="logo-sm">
                    <img src="data:image/png;base64,{{smallDarkLogo}}" alt="Logo Direct CAYC" height="28">
                </span>
        <span class="logo-lg">
                    <img src="data:image/png;base64,{{darkLogo}}" alt="Logo Direct CAYC" height="32">
                </span>
      </a>
      <a routerLink="/" class="logo logo-dark text-left">
                <span class="logo-sm">
                    <img src="data:image/png;base64,{{smallNormalLogo}}" alt="Logo Direct CAYC" height="28">
                </span>
        <span class="logo-lg">
                    <img src="data:image/png;base64,{{normalLogo}}" alt="Logo Direct CAYC" height="32">
                </span>
      </a>
    </div>

    <ul class="list-unstyled topnav-menu topnav-menu-left mb-0">
      <li *ngIf="layoutType==='vertical'">
        <button class="button-menu-mobile disable-btn waves-effect open-left"
                (click)="toggleMobileMenu($event)">
          <i class="fe-menu"></i>
        </button>
      </li>

      <li *ngIf="layoutType==='horizontal'">
        <!-- Mobile menu toggle (Horizontal Layout)-->
        <a class="navbar-toggle nav-link horizontal-toggle" (click)="toggleMobileMenu($event)">
          <div class="lines">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </a>
        <!-- End mobile menu toggle-->
      </li>

      <li *ngIf="layoutType==='vertical'">
        <h4 class="page-title-main">{{pageTitle}}</h4>
      </li>

    </ul>

    <div class="clearfix"></div>
  </div>
</div>
<!-- end Topbar -->

