import { Injectable } from '@angular/core';


@Injectable({ providedIn: 'root' })
export class ThemingService {

  user: any;

  constructor() {
  }

  setCompanyTheme() {
    const appData = sessionStorage.getItem('appData');
    if (appData !== null) {
      const primaryColor = '#' + JSON.parse(appData).primaryColor;
      document.documentElement.style.setProperty('--ct-link-hover-color', primaryColor);
      document.documentElement.style.setProperty('--ct-menu-item-hover', primaryColor);
      document.documentElement.style.setProperty('--ct-menu-item-active', primaryColor);
      document.documentElement.style.setProperty('--ct-link-color', primaryColor);
      document.documentElement.style.setProperty('--ct-component-active-bg', primaryColor);
      document.documentElement.style.setProperty('--ct-progress-bar-bg', primaryColor);
      document.documentElement.style.setProperty('--ct-menu-sub-item-active', primaryColor);
      document.documentElement.style.setProperty('--ct-blue', primaryColor);
      document.documentElement.style.setProperty('--ct-primary-rgb', primaryColor);
      document.documentElement.style.setProperty('--ct-primary', primaryColor);
      document.documentElement.style.setProperty('--ct-form-check-input-checked-bg-color', primaryColor);
      document.documentElement.style.setProperty('--ct-form-check-input-checked-border-color', primaryColor);
      document.documentElement.style.setProperty('--ct-component-active-bg', primaryColor);
    }
  }

}
