import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// utility
import { loggedInUser } from '../helpers/utils';

// types
import { User } from '../models/auth.models';
import { environment } from "../../../environments/environment";


@Injectable({ providedIn: 'root' })
export class AuthenticationService {

  user: any;

    constructor (private http: HttpClient) {
    }

    /**
     * Returns the current user
     */
    public currentUser(): User | null {
        if (!this.user) {
            this.user = loggedInUser();
        }
        return this.user;
    }

    /**
     * Performs the login auth
     * @param username email of user
     * @param password password of user
     */
    login(username: string, password: string): Observable<User> {
        const url = environment.URL_API + '/users/login-check';
        return this.http.post<User>(url, { username, password })
            .pipe(map(user => {
                return user;
            }));
    }

    /**
     * Performs the signup auth
     * @param name name of user
     * @param email email of user
     * @param password password of user
     */
    signup(name: string, email: string, password: string): Observable<User> {
        return this.http.post<User>(`/api/signup`, { name, email, password })
            .pipe(map(user => user));

    }

    /**
     * Logout the user
     */
    logout(): void {
        sessionStorage.removeItem('currentUser');
        this.user = null;
    }

}

