<footer class="footer">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-6 col-xs-6 left">
        <a href="https://gesthispania.com/" target="_blank"><img class="logo-footer" src="assets/images/logotipo-gesthispania-positivo-2023.png" alt="Logo Gesthispania" title="Logo Gesthispania" width="130"></a>
      </div>
      <div class="col-md-6 col-xs-6 d-none right">
        <div class="btn-group-vertical mb-2" ngbDropdown>
          <button type="button" class="help btn btn-outline-secondary dropdown-toggle" ngbDropdownToggle>
            <i class="mdi mdi-help-circle-outline"></i> Ayuda <i class="mdi mdi-chevron-down"></i> </button>
          <div ngbDropdownMenu>
            <a ngbDropdownItem href="/faqs">FAQ</a>
            <a ngbDropdownItem href="/legal">Aviso legal</a>
            <a ngbDropdownItem href="/privacy">Política de privacidad</a>
            <a ngbDropdownItem href="/cookies">Ley Cookies</a>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-xs-6 d-xs-none">
        <div class="text-md-end footer-links d-sm-block">
          <a href="/faqs">FAQ</a>
          <a href="/legal">Aviso legal</a>
          <a href="/privacy">Política de privacidad</a>
          <a href="/cookies">Ley Cookies</a>
        </div>
      </div>
    </div>
  </div>
</footer>
