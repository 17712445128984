import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthenticationService } from '../service/auth.service';
import { Router, NavigationExtras } from "@angular/router";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private extras: NavigationExtras | undefined;
  constructor(private authenticationService: AuthenticationService, private router: Router) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
      const errorMessage = err.error.message || err.statusText;
      this.extras = {
        state: {
          error: errorMessage
        }
      };
      if (err.status === 401 || err.status === 409) {
        this.authenticationService.logout();
        this.router.navigate(['/401'], this.extras);
      } else {
        this.authenticationService.logout();
        this.router.navigate(['/500'], this.extras);
      }
      return throwError(errorMessage);
    }));
  }
}
