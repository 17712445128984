import { Component, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';

@Component({
  selector: 'app-pages-error500',
  templateUrl: './error500.component.html',
  styleUrls: ['./error500.component.scss']
})
export class Error500Component implements OnInit {

  public errorMessage = '¡Vaya! Parece que algo no funcionó correctamente, revise su conexión a internet o inténtelo pasados unos minutos. Disculpe las molestias.';
  public extras: NavigationExtras | undefined;

  constructor(private router: Router) {
    const navigation = this.router.getCurrentNavigation();
    if (navigation) {
      const state = navigation.extras.state as {
        error?: string
      };
      if (typeof(state) !== 'undefined') {
        // @ts-ignore
        if (state.error.includes('not found')) {
          this.extras = {
            state: {
              error: 'El usuario introducido no existe'
            }
          };
          this.router.navigate(['/401'], this.extras);
        }
      } else {
        this.router.navigate(['']);
      }
    }
  }

  ngOnInit(): void {
  }

}
