import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

// service
import { AuthenticationService } from 'src/app/core/service/auth.service';
import { EventService } from 'src/app/core/service/event.service';

// constant
import { EventType } from 'src/app/core/constants/events';

// types
import { User } from 'src/app/core/models/auth.models';
import { NotificationItem } from '../models/notification.model';
import { ProfileOptionItem } from '../models/profileoption.model';
import { SearchResultItem, SearchUserItem } from '../models/search.model';

// data
import { NOTIFICATIONS, PROFILEOPTIONS } from './data';
import { PageTitle } from '../models/page-title.model';
import {environment} from "../../../../environments/environment";
import {HttpClient, HttpHeaders} from "@angular/common/http";

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit {

  notificationList: NotificationItem[] = [];
  profileOptions: ProfileOptionItem[] = [];
  searchResults: SearchResultItem[] = [];
  searchUsers: SearchUserItem[] = [];
  pageTitle: string = '';
  loggedInUser: User | null = null;
  topnavCollapsed: boolean = false;
  normalLogo = '';
  smallNormalLogo = '';
  darkLogo = '';
  smallDarkLogo = '';
  userToken = '';

  @Input() layoutType: string = 'vertical';
  @Input() containerClass?: string = '';

  // output events
  @Output() mobileMenuButtonClicked = new EventEmitter<void>();
  tourState = true;
  state = '';
  currentUser: any;
  showToastWithTourOnMessage = false;
  tourMessage = '';

  constructor (
    private authService: AuthenticationService,
    private eventService: EventService,
    private http: HttpClient) {
    this.eventService.on(EventType.CHANGE_PAGE_TITLE).subscribe(({ payload }) => {
      this.pageTitle = (payload as PageTitle).title;
    });
  }

  ngOnInit(): void {
    this._fetchSearchData();
    this._fetchNotifications();
    this._fetchProfileOptions();
    this.loggedInUser = JSON.parse(sessionStorage.getItem('currentUser')!);
    this.getAppData();
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser')!);
    if (this.currentUser !== null) {
      this.userToken = this.currentUser.token;
    }
    if (localStorage.getItem('tour') === 'ON') {
      this.tourState = true;
      this.state = 'ON';
    } else {
      this.tourState = false;
      this.state = 'OFF';
    }
  }

  changeTourState(): any {
    this.tourState = !this.tourState;
    this.showToastWithTourOnMessage = true;
    if (this.tourState) {
      this.state = 'ON';
      this.tourMessage = 'Activando ayuda...';
    } else {
      this.state = 'OFF';
      this.tourMessage = 'Desactivando ayuda...';
    }
    localStorage.setItem('tour', this.state);
    const data = {
      idUser: this.currentUser.id,
      tour: this.state
    };
    const url = environment.URL_API + '/users/edit-user';
    const headers: HttpHeaders = new HttpHeaders().set('Authorization', `Bearer ${this.userToken}`);
    return this.http.post<any>(url, data, {headers: headers}).subscribe(
      response => {
        this.showToastWithTourOnMessage = false;
        window.location.reload();
      }
    );
  }

  getAppData() {
    const appData = sessionStorage.getItem('appData');
    if (appData !== null) {
      this.normalLogo = JSON.parse(appData).normalLogo;
      this.smallNormalLogo = JSON.parse(appData).smallNormalLogo;
      this.darkLogo = JSON.parse(appData).darkLogo;
      this.smallDarkLogo = JSON.parse(appData).smallDarkLogo;
    }
  }

  /**
   * Fetches notifications
   */
  _fetchNotifications(): void {
    this.notificationList = NOTIFICATIONS;
  }

  /**
   * Fetches profile options
   */
  _fetchProfileOptions(): void {
    this.profileOptions = PROFILEOPTIONS;
  }


  /**
   * Fetches search results
   */
  _fetchSearchData(): void {
    this.searchResults = [{
      id: 1,
      text: 'Analytics Report',
      icon: 'fe-home',
    },
    {
      id: 2,
      text: 'How can I help you?',
      icon: 'fe-aperture',
    },
    {
      id: 3,
      text: 'User profile settings',
      icon: 'fe-settings',
    }];

    this.searchUsers = [{
      id: 1,
      name: 'Erwin Brown',
      position: 'UI Designer',
      profile: 'assets/images/users/user-2.jpg'
    },
    {
      id: 2,
      name: 'Jacob Deo',
      position: 'Developer',
      profile: 'assets/images/users/user-5.jpg'
    }]

  }

  /**
   * Toggles the right sidebar
   */
  toggleRightSidebar() {
    this.eventService.broadcast(EventType.SHOW_RIGHT_SIDEBAR, true);
  }

  /**
* Toggle the menu bar when having mobile screen
*/
  toggleMobileMenu(event: any) {

    this.topnavCollapsed = !this.topnavCollapsed;
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

}
