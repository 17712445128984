import { MenuItem } from '../models/menu.model';

const MENU_ITEMS_USER_ROLE: MenuItem[] = [
  { key: 'options', label: 'Operativa', isTitle: true },
    {
        key: 'dashboard',
        label: 'Dashboard',
        isTitle: false,
        icon: 'mdi mdi-view-dashboard-outline',
        url: '/dashboard',
    },
    {
        key: 'upload',
        label: 'Enviar relación',
        isTitle: false,
        icon: 'mdi mdi-cloud-upload-outline',
        url: '/upload',
    },
    {
        key: 'listing',
        label: 'Listado arrendamientos',
        isTitle: false,
        icon: 'mdi mdi-file-document-multiple-outline',
        url: '/listing',
    },
    { key: 'components', label: 'Ayuda', isTitle: true },
];

const MENU_ITEMS_ADMIN_ROLE: MenuItem[] = [
  { key: 'options', label: 'Operativa', isTitle: true },
  {
    key: 'dashboard',
    label: 'Dashboard',
    isTitle: false,
    icon: 'mdi mdi-view-dashboard-outline',
    url: '/dashboard',
  },
  {
    key: 'upload',
    label: 'Enviar relación',
    isTitle: false,
    icon: 'mdi mdi-cloud-upload-outline',
    url: '/upload',
  },
  {
    key: 'listing',
    label: 'Listado arrendamientos',
    isTitle: false,
    icon: 'mdi mdi-file-document-multiple-outline',
    url: '/listing',
  },
  { key: 'settings', label: 'Configuración', isTitle: true },
  {
    key: 'users',
    label: 'Usuarios',
    isTitle: false,
    icon: 'mdi mdi-account-group-outline',
    url: '/users',
  },
  {
    key: 'certificates',
    label: 'Certificados',
    isTitle: false,
    icon: 'mdi mdi-certificate',
    url: '/certificates',
  },
  {
    key: 'settings',
    label: 'Ajustes',
    isTitle: false,
    icon: 'mdi mdi-cog-outline',
    url: '/settings',
  },
  { key: 'components', label: 'Ayuda', isTitle: true },
];

export { MENU_ITEMS_ADMIN_ROLE, MENU_ITEMS_USER_ROLE };
