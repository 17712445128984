import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ThemingService } from "./core/service/theming.service";
import { IdleService } from "./core/service/idle.service";
import { environment } from "../environments/environment";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Router } from "@angular/router";
import { Location, LocationStrategy } from '@angular/common';
import { NgcCookieConsentService, NgcInitializeEvent, NgcNoCookieLawEvent, NgcStatusChangeEvent } from "ngx-cookieconsent";
import { Subscription } from "rxjs";
import { TranslateService } from "@ngx-translate/core";



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit{
  title = 'cayc-front';
  // @ts-ignore
  favicon: HTMLLinkElement = document.querySelector('#appIcon');
  @ViewChild('staticBackdropModal') staticBackdropModal: ElementRef | undefined;
  modalRef: any;
  private popupOpenSubscription: Subscription | undefined;
  private popupCloseSubscription: Subscription | undefined;
  private initializeSubscription: Subscription | undefined;
  private statusChangeSubscription: Subscription | undefined;
  private revokeChoiceSubscription: Subscription | undefined;
  private noCookieLawSubscription: Subscription | undefined;

  constructor(private ts: ThemingService,
              private idleService: IdleService,
              private modalService: NgbModal,
              private router: Router,
              private location: Location,
              private ccService: NgcCookieConsentService,
              private translateService: TranslateService,
              private url: LocationStrategy) {
  }

  ngOnInit(): void {
    const appData = sessionStorage.getItem('appData');
    if (appData !== null) {
      this.favicon.href = 'data:image/png;base64,' + JSON.parse(appData).icon;
      this.ts.setCompanyTheme();
    }
    this.initialIdleSettings();
    this.showCookiesBar();
  }

  showCookiesBar() {
    this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(
      () => {

      });

    this.popupCloseSubscription = this.ccService.popupClose$.subscribe(
      () => {

      });

    this.initializeSubscription = this.ccService.initialize$.subscribe(
      (event: NgcInitializeEvent) => {

      });

    this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
      (event: NgcStatusChangeEvent) => {

      });

    this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(
      () => {

      });

    this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe(
      (event: NgcNoCookieLawEvent) => {

      });
    this.translateService.addLangs(['en', 'es']);
    this.translateService.setDefaultLang('es');

    const browserLang = 'es';
    this.translateService.use(browserLang);

    this.translateService
      .get(['cookie.header', 'cookie.message', 'cookie.dismiss', 'cookie.allow', 'cookie.deny', 'cookie.link', 'cookie.policy'])
      .subscribe(data => {
        this.ccService.getConfig().content = this.ccService.getConfig().content || {} ;
        // @ts-ignore
        this.ccService.getConfig().content.allow = data['cookie.allow'];
        // @ts-ignore
        this.ccService.getConfig().content.deny = data['cookie.deny'];
        this.ccService.destroy();
        this.ccService.init(this.ccService.getConfig());
      });
  }

  ngOnDestroy() {
    // @ts-ignore
    this.popupOpenSubscription.unsubscribe();
    // @ts-ignore
    this.popupCloseSubscription.unsubscribe();
    // @ts-ignore
    this.initializeSubscription.unsubscribe();
    // @ts-ignore
    this.statusChangeSubscription.unsubscribe();
    // @ts-ignore
    this.revokeChoiceSubscription.unsubscribe();
    // @ts-ignore
    this.noCookieLawSubscription.unsubscribe();
  }

  private initialIdleSettings() {
    const idleTimeoutInSeconds: number = environment.idleTimeInMinutes * 60;
    this.idleService.startWatching(idleTimeoutInSeconds).subscribe((isTimeOut: boolean) => {
      if (isTimeOut) {
        if(!this.location.path().includes('auth')){
          this.modalRef = this.modalService.open(this.staticBackdropModal, { backdrop: 'static', keyboard: false });
          this.idleService.stopTimer();
        }
      }
    });
  }

  logOut() {
    this.modalService.dismissAll();
    this.router.navigate(['/auth/logout']);
  }

}
